import Box from '@material-ui/core/Box'
import NoSsr from '@material-ui/core/NoSsr'
import Typography from '@material-ui/core/Typography'
import { StoryblokComponent } from '@sb-konzept/gatsby-source-storyblok'
import FullScreenLoader from 'components/FullScreenLoader'
import { graphql, useStaticQuery } from 'gatsby'
import * as R from 'ramda'
import React, { Suspense, useMemo } from 'react'
import { Helmet } from 'react-helmet'

import PageLayout from '../components/PageLayout'

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    {
      layout: storyblokLayoutStory(full_slug: { eq: "layouts/default" }) {
        ...storyblokLayoutStory
      }
    }
  `)

  const { layout } = data
  const { header, footer } = useMemo(
    () =>
      R.applySpec({
        header: R.pathOr(null, ['content', 'header', 0]),
        footer: R.pathOr(null, ['content', 'footer', 0])
      })(layout),
    [layout]
  )

  return (
    <PageLayout>
      <Helmet
        defaultTitle="TWIN-Taekwondo"
        defer={false}
        htmlAttributes={{ lang: 'de' }}
        title="TWIN-Taekwondo"
      >
        <meta content="noindex,nofollow" name="robots" />
        <meta content="TWIN-Taekwondo" name="description" />
      </Helmet>
      {header && <StoryblokComponent content={header} />}

      <NoSsr>
        <Suspense fallback={<FullScreenLoader />}>
          <Box display="flex" flexDirection="column" minHeight="100vh">
            <Box flex="1" height="100%" mx={{ xs: 2, md: '10vw' }} py={6}>
              <Typography component="h1" variant="h2">
                PAGE NOT FOUND
              </Typography>
              <Typography component="p" variant="h5">
                We're having trouble finding that page
              </Typography>
            </Box>
          </Box>
        </Suspense>
      </NoSsr>

      {footer && <StoryblokComponent content={footer} />}
    </PageLayout>
  )
}

export default NotFoundPage
